<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container">
      <section class="section--listing-form">
        <client-only>
          <listing-form formProp="edit" />
        </client-only>
      </section>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
import ListingForm from '@/components/listing-form/index';

export default {
  name: 'listing-form-edit',
  components: {
    DefaultLayout,
    ListingForm,
  },
  // eslint-disable-next-line no-unused-vars
  async fetch({ store, params, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    // let listingType = 'R'; // default
    // if (params.listingType) listingType = params.listingType;
    // if (!store.getters['global/allowAddSellListing']) listingType = 'R';
    // await store.commit('v2/listingForm/SET_LISTING_TYPE', listingType);
  },
  data() {
    return {
      e6: 1,
    };
  },
  computed: {
    ...mapState({}),
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('general.editProperty'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    navigateForm(index) {
      this.e6 = index;
    },
  },
};
</script>
